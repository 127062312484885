<template>
  <div class="container bg-white p-10">
    <div class="title">今日实时&nbsp;&nbsp;{{ day }} {{ time }}</div>
    <div class="deal_list_card">
      <div class="list_card"  style="background-color:#e6b334;">
        <div class="list_card_title font_colors">采购金额</div>
        <div class="list_card_price">
          ￥{{ orderData.orderStatisticsResultl.orderPrice || 0 }}
        </div>
      </div>
      <div class="list_card" style="background-color:#e6a9a9;">
        <div class="list_card_title font_colors" >采购订单数</div>
        <div class="list_card_price">
          {{ orderData.orderStatisticsResultl.orderCount || 0 }}
        </div>
      </div>
      <div class="list_card" style="background-color: #b8e3d6;">
        <div class="list_card_title font_colors" >采购商品数</div>
        <div class="list_card_price">
          {{ orderData.orderStatisticsResultl.productCount || 0 }}
        </div>
      </div>
    </div>
    <div class="deal_charts">
      <Echarts v-if="show" :options="orderData"></Echarts>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import Echarts from '@/components/Echarts'
import * as API from '@/api/Order'
export default {
    name: 'DealData',
    components:{Echarts},
    data() {
        return {
            moment,
            selectIndex: 0,
            selectTime: [],
            data: [{
                value: '昨天'
            }, {
                value: '近7天'
            }, {
                value: '近30天'
            }],
            show:false,
            paramsData:{
	            endTime: '2022-11-04',
	            real: 1, //1实时 || 2非实时
	            startTime: '2022-11-03',
	            type: 1, //1交易数据 || 2售后数据
	            userId: ''
            },
            orderData:{
                orderCountList:[0, 0],
                orderPriceList:[],
                orderStatisticsResultl:{
                    orderPrice:0,
                    orderCount:0,
                    productCount:0,
                    refundRate:0
                },
                productCountList:[],
                refundRateList:[]
            },
            time:null,
            day:''
        }
    },
    created(){
        this.getOrderDeal()
        setInterval(()=>{
            let time = new Date()
            this.time = time.toLocaleTimeString()
        },1000)
        this.getDay()
    },
    methods: {
        getDay(){
          let day = new Date()
          let n = day.getFullYear()
          let y = day.getMonth() + 1
          let r = day.getDate()
          console.log('梵蒂冈的',n,y,r)
          this.day = n+'-'+y+'-'+r
        },
        /**
        * 日期选择器回调
        * @param dates
        * @param dateStrings
        */
        rangePickerOnChange(dates, dateStrings) {
            this.paramsData.startTime = dateStrings[0]
            this.paramsData.endTime = dateStrings[1]
            this.getOrderDeal()
        },
        //获取统计数据
        getOrderDeal(){
            let _this = this
            API.orderDeal(this.paramsData).then(res=>{
                _this.orderData = res.data
                this.show = true
            }).catch(e=>e)
        },
        handleDS(i){
            if(this.paramsData.startTime == '' && this.paramsData.endTime == ''){
                this.$message.error('时间不能为空')
                return
            }
            if(i==1){
                this.paramsData.type = i
                this.getOrderDeal()
            }else if(i == 2){
                this.paramsData.type = i
                this.getOrderDeal()
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.font_colors {
  font-size: 22px!important;
  color: white!important;
}
.container {
    margin: 10px;
    padding: 10px 10px 10px 10px;
    // border: 1px solid red;

    .title {
        font-size: 14px;
        font-weight: bold;
        color: #040404;
    }

    .deal_title {
        height: 40px;
        border-bottom: 1px solid #c2c2c2;
        display: grid;
        grid-template-columns: repeat(2, 140px);
        grid-template-rows: 30px;
        grid-column-gap: 0;
        grid-row-gap: 0px;
        margin-top: 10px;

        .left,
        .right {
            cursor: pointer;
            // border: 1px solid red;
            line-height: 30px;
            font-size: .6rem;
            font-weight: 600;
        }

        .active {
            color: $color-main;
        }
    }

    .deal_time {
        display: flex;
        margin: 12px 0 0 0;

        .right {
            margin-left: 20px;
            display: flex;

            >div {
                cursor: pointer;
                width: 55px;
                height: 30px;
                line-height: 30px;
                margin-right: 10px;
                text-align: center;
                background-color: #fff;
                color: #b3b3b3;
                box-shadow: 0 0 10px #bebebe;
            }

            .active {
                background-color: $color-main;
                color: #fff;
            }
        }
    }

    .deal_list_card{
        display: grid;
        grid-template-columns: repeat(3, 200px);
        grid-template-rows: 120px;
        grid-column-gap: 20px;
        grid-row-gap: 0px;
        margin: 10px 0 0 0;
        .list_card{
            position: relative;
            box-shadow: 0 0 10px #bebebe;
            border-radius: 5px;
            margin-top: 10px;
            .list_card_title{
                position: absolute;
                top: 10px;
                left: 10px;
                font-size: .7rem;
                color: #c2c2c2;
            }

            .list_card_price{
                position: absolute;
                top: 60%;
                left: 10px;
                transform: translateY(-50%);
                font-size: 26px;
                font-weight: 500;
                color: #ffffff;
            }
        }
    }
    .deal_charts{
      padding: 20px;
      border-radius: 20px;
      border: 1px solid rgba(140, 140, 140, 0.58);
      margin: 15px 0 0 0;
    }
}
</style>
